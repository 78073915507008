@use 'styles/old-colors' as *;
@use 'styles/tokens' as *;
@use 'styles/mixins' as *;

.root {
  display: flex;
  align-items: center;
  flex-direction: column;

  .icon {
    max-width: 70px;
    max-height: 70px;
    min-width: 40px;
  }

  .error {
    color: $error-color;
    font-size: 1.1rem;
    text-transform: capitalize;
    padding: 1rem;
    &::before {
      content: '* ';
    }
  }

  .errorListItem {
    font-size: 1.1rem;
  }
}
